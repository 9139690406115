import { CONFIG_URL } from "../Settings/envConstants";
import { msalInstance } from "./auth_config";

export const acquireAccessToken = async () => {
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();

    //     /*
    //     * User is not signed in. Throw error or wait for user to login.
    //     * Do not attempt to log a user in outside of the context of MsalProvider
    //     */   

    const request = {
        scopes: [`api://${CONFIG_URL.clientId}/user_impersonation`],
        account: activeAccount || accounts[0]
    };

    const authResult = await msalInstance.acquireTokenSilent(request);

    return authResult.accessToken
};