import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { storeChat } from "../../Services/Redux/Reducers/chatSlice";
import { loader } from "../../Services/Redux/Reducers/commonSlice";

function ProductListingGrid(props) {
  const isOdd = props.products?.length % 2 !== 0;
  const imagesWithPlaceholder = isOdd
    ? [...props.products, { id: "placeholder", type: "placeholder" }]
    : props.products;
  const dispatch = useDispatch();
  const conList = useSelector((state) => state.chat.conversationList);

  useEffect(()=>{
    if(props.index === conList.length-1 && props.isAnswerComplete) {
      dispatch(storeChat(true));
      dispatch(loader(false));
    }
  }, [props.isAnswerComplete])

  const getSCImage = (img) => {
    let selectedImage = [];
    selectedImage =
      img &&
      img.length > 0 &&
      img.filter((item) => item.type === "PrimaryImage");

    return (
      <div className="brandingcss">
        {selectedImage && selectedImage.length > 0 && (
          <img
            className="img-res"
            src={
              selectedImage[0].url !== ""
                ? selectedImage[0].url.split("?fmt=webp-alpha")[0] +
                  "?$rl_4x5_pdp$&fmt=webp-alpha"
                : "./static/assets/no-image.png"
            }
            alt="no img"
          />
        )}
      </div>
    );
  };

  return (
    <div className="card animate-card">
      <div className="card-body p-0 ">
        <h5 className="card-title animate-card-title">Gift for MOM</h5>
        <p className="card-text animate-card-text">{props.text}</p>
        <div className="row bs_gutter">
          {imagesWithPlaceholder?.map((products) => (
            <div className="col-6 col-sm-6 col-md-4 col-lg-4">
              {products.type === "placeholder" ? (
                <div className="placeholder-img"></div>
              ) : (
                <div className="products">
                  <div className="d-flex sc-img-div img-bg">
                    {getSCImage(products.media)}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <p className="card-text animate-conclusion mt-3 mb-3">{props.conclusion}</p>
      </div>
    </div>
  );
}

export default ProductListingGrid;
