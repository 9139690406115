import { Outlet, useLocation } from "react-router-dom";
import Header from "../CommonComponents/Header";
import React, { useEffect, useState } from "react";

function MainLayout() {
  const location = useLocation();
  // code for iphone device
  const [isIos, SetIsIos] = useState(true);

  useEffect(() => {
    let userAgent = window.navigator.userAgent.toLowerCase(),
      macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i,
      windowsPlatforms = /(win32|win64|windows|wince)/i,
      safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if ((macosPlatforms.test(userAgent)) || (windowsPlatforms.test(userAgent)) || ((/android/.test(userAgent)) && !safari)) {
      SetIsIos(false);
    }
  }, []);

  return (
    <div className={`${location.pathname !== "/" ? "mainlayout" : ""}`}>
      <Header />
      <div className={`${!isIos && "mainroute_height"} mainroute`}>
        <Outlet />
      </div>
    </div>
  );
}

export default MainLayout;
