import React, { useState, useEffect } from "react";
import Markdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import {
  conversationList,
  storeChat,
} from "../Services/Redux/Reducers/chatSlice";
import { TypeWriterProps } from "../Services/CommonServices/propTypes";

function Typewriter(props) {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const loading = useSelector((state) => state.common.isLoader);
  const conList = useSelector((state) => state.chat.conversationList);
  const dispatch = useDispatch();
  const isStopTriggered = useSelector(
    (state) => state.chat.isStopButtonTriggered
  );
  const lastConvId = useSelector((state) => state.chat.lastConversation);

  useEffect(() => {
    if (!isStopTriggered && props.effects === true && loading) {
      if (props.text) {
        if (currentIndex < props.text.length) {
          const timeout = setTimeout(() => {
            const displayedText = currentText;
            setCurrentText((prevText) => prevText + props.text[currentIndex]);
            setCurrentIndex((prevIndex) => prevIndex + 1);
            if (
              props.responseType !== "ProductCollectionListing" &&
              props.isAnswerComplete &&
              props.text.length - 1 <= displayedText.length
            ) {
              props.setLoading(true);
            } else if(props.responseType === "ProductCollectionListing" && props.text.length - 1 <= displayedText.length) {
              props.setLoading(true)
            }
          }, props.delay);

          return () => clearTimeout(timeout);
        } else {
          const displayedText = currentText;
          if (props.updateNextText) {
            props.updateNextText(true);
          }
          if (
            props.responseType !== "ProductCollectionListing" &&
            props.isAnswerComplete &&
            props.text.length - 1 <= displayedText.length
          ) {
            props.setLoading(true);
          } else if(props.responseType === "ProductCollectionListing" && props.text.length - 1 <= displayedText.length) {
            props.setLoading(true)
          }
        }
      } else {
        props.setLoading(true);
      }
    } else {
      props.setLoading(true);
    }
  }, [
    currentIndex,
    props.delay,
    props.text,
    props.isAnswerComplete,
    loading,
    isStopTriggered,
  ]);

  useEffect(() => {
    // console.log(
    //   localStorage.getItem("stoppedResponseId"),
    //   props.convoId,
    //   lastConvId,
    //   "props.convoIdprops.convoId"
    // );
    // console.log("type of response: ", props.type);

    if (
      isStopTriggered &&
      localStorage.getItem("stoppedResponseId") === lastConvId
    ) {
      console.log("Abara Bro");

      let convoList = cloneDeep(conList);
      console.log("conlist at start", conList);

      const conIndex = convoList.findIndex(
        (element) => element.uid === props.convoId
      );

      console.log(
        "conversation at index : ",
        conIndex,
        " is ",
        convoList[conIndex]
      );

      if (props.type === "product") {
        // console.log(props.productIndex,'props.propertyKey', convoList[conIndex]["response"]["products"])
        convoList[conIndex]["response"]["products"][props.productIndex][
          props.propertyKey
        ] = currentText;
        convoList[conIndex]["response"]["products"][
          props.productIndex
        ].responseStopped = true;
        
        if (props.propertyKey === "type") {
          convoList[conIndex]["response"]["products"][props.productIndex][
            "name"
          ] = "";
        }
        if (props.propertyKey === "name") {
          convoList[conIndex]["response"]["products"][props.productIndex][
            "description"
          ] = "";
        }
        const productIndex = convoList[conIndex]["response"][
          "products"
        ].findIndex((element, i) => i === props.productIndex);
        convoList[conIndex]["response"]["products"] = convoList[conIndex][
          "response"
        ]["products"].filter((product, index) => {
          return index <= productIndex;
        });
        convoList[conIndex]["response"]["responseConclusion"] = "";
        convoList[conIndex]["response"]["products"] = [
          ...convoList[conIndex]["response"]["products"],
        ];
        convoList[conIndex]["response"].responseStopped = true;
      } 
      else if (props.type === "productListing") {
        console.log(
          "productListing conversation at index : ",
          conIndex,
          " is ",
          convoList[conIndex]
        );

        convoList[conIndex]["response"][
          "outfitUrl"
        ] = props.brandingImage;

        convoList[conIndex]["response"][
          props.propertyKey
        ] = currentText;

        convoList[conIndex]["response"]["products"] = [];

        // if (props.propertyKey === "responseText") {
        //   convoList[conIndex]["response"]["products"] = [];
        // }

      } 
      else if (props.type === "productCollectionListing") {
        console.log(
          "collection conversation at index : ",
          conIndex,
          " is ",
          convoList[conIndex]
        );


        convoList[conIndex]["response"]["productCollections"][props.productIndex][
          props.propertyKey
        ] = currentText;
        convoList[conIndex]["response"]["productCollections"][
          props.productIndex
        ].responseStopped = true;

        const productIndex = convoList[conIndex]["response"][
          "productCollections"
        ].findIndex((element, i) => i === props.productIndex);
        convoList[conIndex]["response"]["productCollections"] = convoList[conIndex][
          "response"
        ]["productCollections"].filter((product, index) => {
          return index <= productIndex;
        });
        
        convoList[conIndex]["response"]["responseConclusion"] = "";
        convoList[conIndex]["response"]["productCollections"] = [
          ...convoList[conIndex]["response"]["productCollections"],
        ];
        convoList[conIndex]["response"].responseStopped = true;


      } else if (props.type === "productDetail") {
        console.log(
          "conflicting conversation at index : ",
          conIndex,
          " is ",
          convoList[conIndex]
        );

        convoList[conIndex]["response"]["products"][props.productIndex][
          props.propertyKey
        ] = currentText;

        convoList[conIndex]["response"]["products"][
          props.productIndex
        ].responseStopped = true;

        if (props.propertyKey === "name") {
          convoList[conIndex]["response"]["products"][props.productIndex][
            "description"
          ] = "";
        }
        convoList[conIndex]["response"]["responseConclusion"] = "";
      } else {
        console.log(convoList, "convoList", props.type);

        convoList[conIndex]["response"][props.type] = currentText;
        convoList[conIndex]["response"].responseStopped = true;
      }
      convoList[conIndex]["response"].suggestedPrompts = [];
      if (
        props.type === "responseText" &&
        convoList[conIndex]["response"].products &&
        convoList[conIndex]["response"].products.length > 0
      ) {
        convoList[conIndex]["response"]["products"] = [];
      }
      if (
        props.type === "responseText" &&
        convoList[conIndex]["response"].branding &&
        convoList[conIndex]["response"].branding.length > 0
      ) {
        convoList[conIndex]["response"]["branding"] = [];
      }

      dispatch(conversationList([...convoList]));
    }
  }, [
    localStorage.getItem("stoppedResponseId"),
    currentText,
    isStopTriggered,
    lastConvId,
  ]);

  useEffect(() => {
    if (
      isStopTriggered &&
      localStorage.getItem("stoppedResponseId") === lastConvId
    ) {
      dispatch(storeChat(true));
    }
  }, [isStopTriggered, conList]);

  return (
    <div className="typewriter">
      {props.effects === false ? (
        <Markdown>{props.text}</Markdown>
      ) : loading && !currentText && !props.hideLoader ? (
        <div style={{ margin: "10px 20px" }} className="cat-loader" />
      ) : (
        <Markdown>{currentText}</Markdown>
      )}
    </div>
  );
}

Typewriter.propTypes = TypeWriterProps;

Typewriter.defaultProps = {
  delay: 10,
  effects: true,
  onComplete: () => {},
  isAnswerComplete: false,
  isStreaming: false,
  isStopResponseTriggered: false,
  setIsStopResponseTriggered: () => {}
};

export default Typewriter;
