import React from "react";
import { DividerProps } from "../Services/CommonServices/propTypes";

function Divider(props) {
  return (
    <div className="divider">
      <div className="row text-center d-flex flex-column justify-content-center align-items-center" >
        <div className="col-12 col-md-12 col-lg-12">
          <div className={`col-12 col-md-12 col-lg-12 mx-lg-auto h4 text-dark border-bottom border-dark ${props.borderTop} divider_margin`}></div>
          <div className={`col-12 col-md-12 col-lg-12 mx-lg-auto h4 mb-0 text-dark border-bottom border-dark ${props.borderBottom} `}></div>
        </div>
      </div>
    </div>
  );
}

Divider.propTypes = DividerProps;

// Default props in case they're not provided
Divider.defaultProps = {
  borderTop: '',
  borderBottom: ''
};

export default Divider;