/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Typewriter from '../../CommonComponents/TypeWriting';
import Divider from '../../CommonComponents/Divider';
import { useDispatch, useSelector } from 'react-redux';
import { loader } from '../../Services/Redux/Reducers/commonSlice';
import { storeChat } from '../../Services/Redux/Reducers/chatSlice';
import { TextOnlyProps } from "../../Services/CommonServices/propTypes";

function TextOnly(props) {
    const [isOverviewTextLoading, setIsOverviewTextLoading] = useState(false);
    const [isConclusionLoading, setIsConclusionLoading] = useState(false);
    const dispatch = useDispatch();
    const conList = useSelector((state) => state.chat.conversationList);
    const loading = useSelector((state) => state.common.isLoader)
    const streamingState = useSelector((state) => state.chat.isStream)
    const isStopTriggered = useSelector((state) => state.chat.isStopButtonTriggered)

    useEffect(() => {
        if(props.index === conList.length-1 && !isStopTriggered && props.isAnswerComplete && loading) {
            if(
                ((props.text === null || props.text  === "") || (props.text!=null && isOverviewTextLoading == true)) &&
                (props.conclusion==undefined || props.conclusion=="" || (props.conclusion && props.conclusion!="" && isConclusionLoading==true))
            ) {
                dispatch(storeChat(true));
                dispatch(loader(false));
            }
        }
        if(!isStopTriggered && !isOverviewTextLoading && !isConclusionLoading && streamingState) {
            dispatch(loader(true));
        }
    }, [props.conclusion, props.isAnswerComplete, props.text, isOverviewTextLoading, isConclusionLoading, loading])

    return (
        <div>
            <div className="TextResponse">
                <div className="chat_options d-flex flex-column mb response_bg">
                    <div className="chat_msg1 col-md-12 col-lg-9 px-0 py-0">
                        <Typewriter convoId={props.convoId} type="responseText" text={props.text} delay={props.delay} isAnswerComplete={props.isAnswerComplete} setLoading={setIsOverviewTextLoading} effects={props.effects}
                        // isStopResponseTriggered={props.isStopResponseTriggered} seIsStopResponseTriggered={props.seIsStopResponseTriggered}
                        />
                    </div>
                    <div>                    
                        { ((props.index !== conList.length-1 && props.conclusion && props.conclusion != '') || ((props.text === "" || isOverviewTextLoading) && props.conclusion && props.conclusion != '')) && (
                            <div>
                                <Divider borderTop="border-1" borderBottom="border-2"/>
                                <div className="col-lg-9 chat_msg1 p-0">
                                <Typewriter type="responseConclusion" convoId={props.convoId} text={props.conclusion??""} delay={props.delay} isAnswerComplete={props.isAnswerComplete} setLoading={setIsConclusionLoading} effects={props.effects}
                                //  isStopResponseTriggered={props.isStopResponseTriggered} seIsStopResponseTriggered={props.seIsStopResponseTriggered} 
                                />                      
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

TextOnly.propTypes = TextOnlyProps;

export default TextOnly;