import axios from "axios";
import { CONFIG_URL } from "./envConstants";
import { acquireAccessToken } from "../Authentication/acquire_token";

// axios configuration with api url
const microsoftInstance = axios.create({
  baseURL: `${CONFIG_URL.microsoftapiUrl}/`,
});

microsoftInstance.interceptors.request.use(
  async (config) => {
    const token = await acquireAccessToken()
    console.log("Request=>", config);
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  }, (error) => {
    // Do Something with error
    console.log("ERROR", error);
    return Promise.reject(error);
  }
);

microsoftInstance.interceptors.response.use(
  (response) => {
    // Do Something after request
    console.log("Response=>", response);
    const { status } = response;
    if (status === 200) {
      return response;
    }
  }, (error) => {
    const { message } = error;
    console.log("ERROR", message);
    return Promise.reject(error);
  }
);

export { microsoftInstance };