import React, { useEffect, useState } from "react";
import MultiProductCard from "./MultiProductCard";
import Typewriter from "../../CommonComponents/TypeWriting";
import { useSelector, useDispatch } from "react-redux";
import {
  loader,
} from "../../Services/Redux/Reducers/commonSlice";
import { storeChat, isStream } from "../../Services/Redux/Reducers/chatSlice";
import { getTitle } from "../../Services/CommonServices/helper";
import { MCatProductCardProps } from "../../Services/CommonServices/propTypes";

const MultiCatTemplate = (props) => {
  const products = props.products;
  const cardLength = products?.length;
  const [isOverviewTextLoading, setIsOverviewTextLoading] = useState(false);
  const [isConclusionLoading, setIsConclusionLoading] = useState(false);
  const [isProductLoading, setIsProductLoading] = useState(false);
  // const [isLastProductLoaded, setIsLastProductLoaded] = useState(false);
  // const [currentProduct, setCurrentProduct] = useState(null);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.common.isLoader);
  const streamingState = useSelector((state) => state.chat.isStream);
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  // const [showConusionLoader, setShowConusionLoader] = useState(false);
  const conList = useSelector((state) => state.chat.conversationList);
  const isStopTriggered = useSelector((state) => state.chat.isStopButtonTriggered)

  useEffect(() => {
    if (props.index === conList.length-1 && !isStopTriggered && props.isAnswerComplete && loading) {
      // console.log('log',(props.text!=null && isOverviewTextLoading == true),(products==undefined || products.length == 0 ) ,(((props.conclusion && props.conclusion!="" && isConclusionLoading==true))));
      if (
        props.text != null &&
        isOverviewTextLoading === true &&
        props.conclusion &&
        props.conclusion != "" &&
        isConclusionLoading &&
        products &&
        cardLength &&
        isProductLoading
      ) {
        dispatch(storeChat(true));
        dispatch(loader(false));
      }
    }
    if (
      !isStopTriggered &&
      !isOverviewTextLoading &&
      !isProductLoading &&
      !isConclusionLoading &&
      streamingState
    ) {
      dispatch(loader(true));
    }
  }, [
    props.conclusion,
    props.isAnswerComplete,
    products,
    props.text,
    isOverviewTextLoading,
    isConclusionLoading,
    loading,
    isProductLoading,
  ]);

  const categoryListing = (products, productIndex) => {
    if (products) {
      let data = {
        text: "Show me details for the " + getTitle(products) + " look.", //as per Peter's comments
        query: "Can you show me details of outfit with ID " + products.id + "?",
        type: props.responseType,
      };
      if (!props.stopClick) {
        props?.onValueChange(data);
      }
      dispatch(isStream(true));
    }
  };

  //  add delay conditionally based on the size of the products, after the last product is loaded
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (
  //       products.length > 0 &&
  //       currentProduct === products.indexOf(products.slice(-1)[0])
  //     ) {
  //       setIsLastProductLoaded(true);
  //       // setShowConusionLoader(true);
  //       // setTimeout(() => {
  //       //   setShowConusionLoader(false);
  //       // }, 2000);
  //     }
  //   }, products.indexOf(products.slice(-1)[0]) * 1000);
  // }, [currentProduct]);

  return (
    <div className="multicat_template">
      <div className="row productdetails-div">
        <div className="col-12 col-lg-8">
          <div className="mcat-text">
            <Typewriter
              convoId={props.convoId}
              type="responseText"
              responseType={props.responseType}
              text={props.text}
              delay={props.delay}
              isAnswerComplete={props.isAnswerComplete}
              setLoading={setIsOverviewTextLoading}
              effects={props.effects}
              updateNextText={setIsProductsLoading}
              propertyKey={"responseText"}
            />
          </div>
        </div>
      </div>

      {(isProductsLoading && products.length === 0) ? loading && (
        <div style={{ margin: "10px 20px" }} className="cat-loader" />
      ) : null}

      {isOverviewTextLoading && products.length > 0 && (
        <div className="col-12">
          <div className="cards">
            {products &&
              products.map((productDetails, productIndex) => (
                <MultiProductCard
                  stopHover={props.stopClick}
                  categoryListing={() => {
                    categoryListing(productDetails, productIndex);
                  }}
                  key={productIndex}
                  product={productDetails}
                  products={products}
                  productIndex={productIndex}
                  cardLength={cardLength}
                  convoId={props.convoId}
                  isAnswerComplete={props.isAnswerComplete}
                  setIsProductLoading={setIsProductLoading}
                  effects={props.effects}
                  // setProductIndex={setCurrentProduct}
                  delay={props.delay}
                />
              ))}
          </div>
          {/* <Typewriter
            text={""}
            delay={props.delay}
            isAnswerComplete={props.isAnswerComplete}
            setLoading={setIsProductLoading}
            effects={props.effects}
            convoId={props.convoId}
            type="productCollectionListing"
            productIndex={currentProduct}
            hideLoader={true}
          /> */}
        </div>
      )}
      {((props.index !== conList.length - 1 && props.conclusion && props.conclusion != "") || ((props.products === undefined ||
                (props.products &&
                  props.products.length === 0 &&
                  (props.isAnswerComplete ||
                    props.isAnswerComplete === undefined)) ||
                isProductLoading) &&
                isOverviewTextLoading &&
                props.conclusion &&
                props.conclusion != "")) ? (
        <div className="row">
          <div className="col-lg-9 mcat-text multi-res">
            <Typewriter
              convoId={props.convoId}
              type="responseConclusion"
              responseType={props.responseType}
              text={props.conclusion}
              delay={props.delay}
              isAnswerComplete={props.isAnswerComplete}
              setLoading={setIsConclusionLoading}
              effects={props.effects}
            />
          </div>
        </div>
      ) : (
        <div>
          {props.products?.length > 0 && isProductLoading && 
            <div style={{ margin: "10px 20px" }} className="cat-loader" />
          }
        </div>
      )}
    </div>
  );
};

MultiCatTemplate.propTypes = MCatProductCardProps;

export default MultiCatTemplate;
