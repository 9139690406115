import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import Typewriter from "../../CommonComponents/TypeWriting";
import Divider from "../../CommonComponents/Divider";
import { useSelector, useDispatch } from 'react-redux';
import { loader } from '../../Services/Redux/Reducers/commonSlice';
import { storeChat } from '../../Services/Redux/Reducers/chatSlice';
import { BrandingCardProps } from "../../Services/CommonServices/propTypes";

function BrandingCard(props) {
    const [isOverviewTextLoading, setIsOverviewTextLoading] = useState(false);
    const [isConclusionLoading, setIsConclusionLoading] = useState(false);
    const [brandingUrl, setBrandingUrl] = useState("");
    const [mediaType, setMediaType] = useState("");
    const dispatch = useDispatch();
    const [isPlaying, setIsPlaying] = useState(false);
    const conList = useSelector((state) => state.chat.conversationList);
    const loading = useSelector((state) => state.common.isLoader)
    const streamingState = useSelector((state) => state.chat.isStream)
    const isStopTriggered = useSelector((state) => state.chat.isStopButtonTriggered)

    useEffect(() => {
        if(props.index === conList.length-1 && !isStopTriggered && props.isAnswerComplete && loading) {
            if((((props.text === null || props.text  === "") || (props.text!=null && isOverviewTextLoading == true)) && (props.branding==undefined || props.branding.length == 0 || props.branding.length>0) && (props.conclusion==undefined || props.conclusion=="" || (props.conclusion && props.conclusion!="" && isConclusionLoading==true)))) {
                dispatch(storeChat(true));
                dispatch(loader(false));
            }
        }
        if(!isStopTriggered && !isOverviewTextLoading && !isConclusionLoading && streamingState) {
            dispatch(loader(true));
        }
    }, [props.conclusion, props.isAnswerComplete, props.branding, props.text, isOverviewTextLoading, isConclusionLoading, loading])

    useEffect(() => {
        if (props.branding) {
            props.branding.map((brand) => {
                brand.media.map((media) => {
                    setMediaType(media.type);
                    setBrandingUrl(media.url);
                })
            })
        }
    }, [props.branding])

    const handlePlayPause = () => {
        setIsPlaying(!isPlaying);
    }

    return (
        <div className="chat_options d-flex flex-column text_res response_bg branding-card" id={`player-${props.index}`}>
            <div className="chat_msg1 col-md-12">
                <div className="chat2">
                    <div className="chat2 col-lg-9">
                        <Typewriter convoId={props.convoId} type='responseText' text={props.text} delay={props.delay} isAnswerComplete={props.isAnswerComplete} setLoading={setIsOverviewTextLoading} effects={props.effects} />
                    </div>
                </div>
                {((props.index !== conList.length - 1 && brandingUrl !== "" && mediaType != '') || ((props.text === "" || isOverviewTextLoading) && brandingUrl !== "" && mediaType !== "")) &&
                    <div id={`player_container-${props.index}`} className="col-lg-7 branding-video-div">
                        {
                            mediaType === "Video" ? (
                                <div className="player-container">
                                    <ReactPlayer url={brandingUrl} playing={isPlaying} loop width="100%" className="branding-video-css" playsinline />
                                    {brandingUrl &&
                                        <div className="col-12 play-pause-overlay">
                                            {isPlaying ?
                                                <img
                                                    src="./static/assets/pause.svg"
                                                    className="play-pause"
                                                    alt="pause"
                                                    onClick={handlePlayPause}
                                                />
                                                :
                                                <img
                                                    src="./static/assets/play.svg"
                                                    className="play-pause"
                                                    alt="play"
                                                    onClick={handlePlayPause}
                                                />
                                            }
                                        </div>
                                    }
                                </div>
                            ) : (
                                <div>
                                    <img src={brandingUrl} width="320px" style={{ aspectRatio: "auto" }}></img>
                                </div>
                            )
                        }
                    </div>
                }
                <div>
                    {((props.index !== conList.length - 1 && props.conclusion && props.conclusion != '') || ((props.text === "" || isOverviewTextLoading) && props.conclusion && props.conclusion != '')) && (
                        <div>
                            <Divider borderTop="border-1" borderBottom="border-2" />
                            <div className="col-lg-9 branding-mb">
                                <Typewriter convoId={props.convoId} type='responseConclusion' text={props.conclusion ?? ""} delay={props.delay} isAnswerComplete={props.isAnswerComplete} setLoading={setIsConclusionLoading} effects={props.effects} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

BrandingCard.propTypes = BrandingCardProps;

export default BrandingCard;