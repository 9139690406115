import React, { useEffect, useState } from 'react';
import Restricted from '../CommonComponents/Restricted';
import { Cookies } from 'react-cookie-consent';
import { PrivateRouteProps } from '../Services/CommonServices/propTypes';
import { getUserId } from '../Services/ApiServices/userServices';

const PrivateRoute = ({ children }) => {
    const [authenticated, setAuthenticated] = useState(false);
    useEffect(() => {
        if(!Cookies?.get('ar_user_email')) {
            try{
                getUserId(process.env.REACT_APP_USERID,(response) => { 
                    if(response.data.ar_user_email && response.data.ar_user_Id  || response.data.ar_user) {
                        Cookies.set('ar_user', response.data.ar_user)
                        Cookies.set('ar_user_Id', response.data.ar_user_Id)
                        Cookies.set('ar_user_email', response.data.ar_user_email)
                        setAuthenticated(true);
                    } else {
                        setAuthenticated(false);
                    }
                }, (err) => {
                    console.log(err);
                    setAuthenticated(false);
                });
              }
             catch(err){
                console.log(err);
                setAuthenticated(false);
            }
        } else if (Cookies?.get('ar_user_email') && (Cookies.get('ar_user_email') !== "" || Cookies.get('ar_user_email') !== null || Cookies.get('ar_user_email') !== undefined)) {
            setAuthenticated(true);
        } else {
            setAuthenticated(false);
        }
    }, [Cookies])

    if(authenticated) {
        return children;
    } else {
        return <Restricted />;
    }
};

PrivateRoute.propTypes = PrivateRouteProps;

export default PrivateRoute;
