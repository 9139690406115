import { useEffect, useRef, useState } from "react";
import TextOnly from "../Components/StreamingComponents/TextOnly";
import SpecificProductDetails from "../Components/StreamingComponents/SpecificProductDetails";
import BrandingCard from "../Components/StreamingComponents/BrandingCard";
// import SingleCategoryList from "../Components/StreamingComponents/SingleCategoryList";
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep } from "lodash";
import { conversationList, storeChat, lastConversation } from "../Services/Redux/Reducers/chatSlice";
import { addchat } from "../Services/ApiServices/chatServices";
import { loader } from "../Services/Redux/Reducers/commonSlice";
import MultiCatTemplate from "../Components/MultiCatCardTemplate/MultiCatTemplate";
import ApplicationFeedback from "./ApplicationFeedback";
import { MessageHistoryProps } from "../Services/CommonServices/propTypes";
import ProductListingGrid from "../Components/StreamingComponents/ProductLisitingGrid";

function MessageHistory(props) {
  const typewritterdelay = process.env.TYPE_WRITER_DELAY || 5
  const conList = useSelector((state) => state.chat.conversationList);
  const loading = useSelector((state) => state.common.isLoader)
  const dispatch = useDispatch();
  const isStore = useSelector((state) => state.chat.storeChat)
  const isStream = useSelector((state) => state.chat.isStream)
  const feedBackModal = useSelector((state) => state.feedBack.appFeedBackModal)
  const [isMobileHeader, setIsMobileHeader] = useState(true);

  const storeMessage = (conHis) => {
    //return
    let convo = cloneDeep(conHis)
    let convoList = cloneDeep(conHis)
    let lastItem = convo.pop();
    if (lastItem.response.responseType === "TextOnly" || lastItem.response.responseType === "BrandingInformation") 
      {
      lastItem.backgroundImage = "";
      lastItem.desktopbackgroundImage = "";
    }
    const conIndex = convoList.findIndex((element) => element.uid === lastItem.uid)
    if (isStream) {
      try {
        addchat(lastItem, (response) => { // Chat History storing the messages     
          convoList[conIndex]['id'] = response.data.id
          convoList[conIndex]['showLikeDislike'] = true
          convoList[conIndex]['error'] = ""
          dispatch(conversationList(convoList));
        }, (err) => {
          console.log("An error occurred in Store message api call  in chat.", err.message);
          dispatch(loader(false));
        });
      }
      catch (err) {
        console.log("An error occurred Store message api call  in chat.", err.message);
      }
    }
  }

  useEffect(() => {
    if (!loading && isStore) {
      let conHis = cloneDeep(conList)
      storeMessage(conHis)
      dispatch(lastConversation(""));
      dispatch(storeChat(false));
    } else {
      dispatch(storeChat(false))
    }
  }, [loading, isStore])

  const handleValueChange = (childValue) => {
    props.onValueChange(childValue)
  };

  useEffect(() => {
    if (window.innerWidth > 576) {
      setIsMobileHeader(false)
    }
    else {
      setIsMobileHeader(true)
    }
  }, [window.innerWidth])

  return (
    <div className="messageHistory">
      {conList &&
        conList.map((con, index) => (
          <div key={"history" + index} >
            <div  className="col-11" style={{ justifySelf: "end" }}>
              <div  className={`chat_msg ms-auto me-3 me-sm-1 fadeOutPromts`}>
                <div className="d-flex align-items-start ps-0">
                  <span id="badge-text" className="badge-text">{con.question}</span>
                </div>
              </div>
            </div>
            {((con && con.response?.responseType && con.response?.responseType === "ProductListing") ||
              (con && con.response?.responseType === "ProductDetail") ||
              (con && con.response?.responseType === "ProductCollectionListing")) && (con.backgroundImage && con.desktopbackgroundImage) && (
              <>
                <div className={`${!isMobileHeader && "d-none"}`}>
                  <img
                    src={con.backgroundImage}
                    alt="headerImg"
                    className="imgHeader animate-header"
                  />
                </div>
                <div className={`${isMobileHeader && "d-none"}`}>
                  <img
                    src={con.desktopbackgroundImage}
                    alt="headerImg"
                    className="imgHeader animate-header"
                  />
                </div>
              </>
            )}
            {
              Object.keys(con.response).length > 0 &&
              <div >
                {
                  con && con.response?.responseType === "Error" && con.response?.error !== "Oops, Request cancelled" &&
                  <div className="text-danger">
                    An error occurred. Please try again
                  </div>
                }
                {con && con.response?.responseType && con.response?.responseType === "TextOnly" && (
                  <TextOnly convoId={con.uid} index={index} responseType={con.response.responseType} text={con.response.responseText} conclusion={con.response.responseConclusion} delay={typewritterdelay} isAnswerComplete={props.isAnswerComplete} stopClick={false} onValueChange={handleValueChange} effects={conList.length - 1 === index && isStream ? true : false} />
                )}
                {con && con.response?.responseType && con.response?.responseType === "BrandingInformation" &&
                  <BrandingCard convoId={con.uid} index={index} text={con.response.responseText} branding={con.response.branding} conclusion={con.response.responseConclusion} delay={typewritterdelay} isAnswerComplete={props.isAnswerComplete} stopClick={false} onValueChange={handleValueChange} effects={conList.length - 1 === index && isStream ? true : false} />
                }
                {con && con.response?.responseType && con.response?.responseType === "ProductListing" && (
                  // <SingleCategoryList convoId={con.uid} index={index} responseTitle={con.response.responseTitle} text={con.response.responseText} outfitUrl={con.response.outfitUrl} branding={con.response.branding} products={con.response.products} conclusion={con.response.responseConclusion} delay={typewritterdelay} isAnswerComplete={props.isAnswerComplete} stopClick={false} onValueChange={handleValueChange} effects={conList.length - 1 === index && isStream ? true : false} />
                  <ProductListingGrid convoId={con.uid} index={index} responseTitle={con.response.responseTitle} text={con.response.responseText} outfitUrl={con.response.outfitUrl} branding={con.response.branding} products={con.response.products} conclusion={con.response.responseConclusion} delay={typewritterdelay} isAnswerComplete={props.isAnswerComplete} stopClick={false} onValueChange={handleValueChange} effects={conList.length - 1 === index && isStream ? true : false} />
                )}
                {con && con.response?.responseType === "ProductDetail" && (
                  <SpecificProductDetails convoId={con.uid} index={index} responseTitle={con.response.responseTitle} text={con.response.responseText} branding={con.response.branding} product={con.response.products} conclusion={con.response.responseConclusion} delay={typewritterdelay} isAnswerComplete={props.isAnswerComplete} stopClick={false} onValueChange={handleValueChange} effects={conList.length - 1 === index && isStream ? true : false} />
                )}
                {con && con.response?.responseType === "ProductCollectionListing" && (
                  <div>
                    <MultiCatTemplate convoId={con.uid} index={index} responseType={con.response.responseType} responseTitle={con.response.responseTitle} text={con.response.responseText} branding={con.response.branding} products={con.response.productCollections} conclusion={con.response.responseConclusion} delay={typewritterdelay} isAnswerComplete={props.isAnswerComplete} stopClick={false} onValueChange={handleValueChange} effects={conList.length - 1 === index && isStream ? true : false} />
                  </div>
                )}
              </div>
            }
          </div>
        ))
      }
      {feedBackModal && <ApplicationFeedback />}
    </div>
  );
}

MessageHistory.propTypes = MessageHistoryProps;

export default MessageHistory;