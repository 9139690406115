
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import PromptsCarousel from "./PromptCarousel/PromptsCarousel";
import ArchiveCarousel from "./ArchiveCarousel/ArchiveCarousel";
import { uniqueGroupSessionHistory } from "../Services/ApiServices/chatServices";
import { useDispatch, useSelector } from "react-redux";
import { apiError,  conversationList, isStream } from "../Services/Redux/Reducers/chatSlice";
import {  loader,  } from "../Services/Redux/Reducers/commonSlice";
import ApplicationFeedback from "../CommonComponents/ApplicationFeedback";
import OnboardCarousel from "./OnboardingCarousel/OnboardCarousel";
import { Cookies } from 'react-cookie-consent';
import { isTwoMonthsFromNow } from "../Services/CommonServices/helper";
import {searchBoxVisible } from "../Services/Redux/Reducers/chatSlice";
import { appFeedBackModal, appLevelFeedback } from "../Services/Redux/Reducers/feedBackSlice";
import { userPrompt } from "../Services/Redux/Reducers/userSlice";
 
function Useronboard() {
  const name = Cookies?.get('ar_user_email') ? Cookies.get('ar_user_email') : "";
  const [input, setInput] = useState("");
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const intervalRef = useRef(null)
  const [isPlaceHolderVisible, setIsPlaceHolderVisible] = useState(true);
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(true);
  const [isKeyBoardOpen, setIsKeyBoardOpen] = useState(false);
  const [animateState, setAnimateState] = useState(false);
  const [onboardLoading, setOnboardLoading] = useState(false);
  const [showCarousel, setShowCarousel] = useState(false);
  const [carouselData, setCarouselData] = useState([]);
  const [isLoading, setIsLoading] = useState(true)
  const [modalDeleteState, setModalDeleteState] = useState(false)
  const [currentMsgIndex, setCurrentMsgIndex] = useState(
    (parseInt(localStorage.getItem('questionIndex')) || 0)
  );
  const [isFocused, setIsFocused] = useState(false);
  const [isAnimating, setIsAnimating] = useState(true);
  const isMounted = useRef(true);
  const prevIndexRef = useRef(0);
  const dispatch = useDispatch();
  const [showLogo, setShowLogo] = useState(true);
  const [showMobileCarousel, setShowMobileCarousel] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [blurBackground, setBlurBackground] = useState(false);
  const customerId = Cookies?.get('ar_user_Id') ? Cookies.get('ar_user_Id') : "";
  const [promptsVisible, setPromptsVisible] = useState(false);
  const [isBackFromArchive, setIsBackFromArchive] = useState(false);
  const [onboardCarouselFadeOut, setOnboardCarouselFadeOut] = useState(false);
  const isSearchBoxVisible = useSelector((state) => state.chat.searchBoxVisible);
  const isUserCheck = useSelector((state) => state.chat.userCheck)

  useEffect(() => {
    dispatch(conversationList([]));
    dispatch(userPrompt(''));
    dispatch(isStream(false));
    dispatch(loader(false));
    const handleResize = () => {
      const mobileView = window.innerWidth <= 576;
      const focused = window.innerHeight < window.screen.height * 0.7;
      setIsKeyBoardOpen(focused);
      setIsMobile(mobileView); // Adjust the width as needed
      if (!mobileView) {
        // Reset to initial state when switching to desktop view
        setShowLogo(true);
        setShowMobileCarousel(false);
        setBlurBackground(false);
        dispatch(searchBoxVisible(true));
        setPromptsVisible(true);
      }
    };

    handleResize(); // Check initial screen size
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if ((carouselData?.length >= 0 && carouselData.isUserExist)) {
      setShowLogo(true);
      setShowMobileCarousel(false);
      setBlurBackground(false);
      dispatch(searchBoxVisible(true));
      setPromptsVisible(true);
    }
    else if (isMobile && carouselData?.length === 0 && !carouselData.isUserExist && !isUserCheck) {
      dispatch(searchBoxVisible(false));
      setPromptsVisible(false);
      const timer = setTimeout(() => {
        setShowLogo(false);
        setTimeout(() => {
          setBlurBackground(true);
          setTimeout(() => {
            setShowMobileCarousel(true);
          }, 1800);
        }, 1000);
      }, 2500);

      return () => clearTimeout(timer);
    }
    else {
      setShowLogo(true);
      setShowMobileCarousel(false);
      setBlurBackground(false);
      dispatch(searchBoxVisible(true));
      setPromptsVisible(true);
    }

  }, [isMobile, carouselData]);

  const handleSkip = () => {
    dispatch(searchBoxVisible(true));
    setPromptsVisible(true);
  }

  const handleGetStartedClick = () => {
    setShowLogo(true);
    setBlurBackground(false);
    setTimeout(() => { setShowMobileCarousel(false); }, 500);
    setIsBackFromArchive(true);
    setOnboardCarouselFadeOut(true);
  };
 
  const modalFlag = useSelector((state) => state.feedBack.appFeedBackModal)
  const summaryData = useSelector((state) => state.feedBack.appLevelFeedback)
  // console.log('modalFlag',modalFlag);

  useEffect(() => {
    // onboarding app level feedback modal 
    if (summaryData && summaryData.length >= 1 && summaryData && summaryData?.feedBack_Length === 0) {
      dispatch(appFeedBackModal(true))
    }
    else if (summaryData && summaryData?.length >= 1 && isTwoMonthsFromNow(summaryData && summaryData?.feedBackData && summaryData?.feedBackData[0]?.requestDateTime)) {
      dispatch(appFeedBackModal(true))
    }
  }, [summaryData])

  //setting the sesssionId empty in session storage
  sessionStorage.removeItem("persist_session");

  const inputMsg = ["What should I wear to a winter wedding?", "Do you have any large Polo shirts in white?", "What gift should I get my significant other?", "What's the origin of the Polo Bear?"]

  // for slider data function
  const storeMessage = async () => {
    try{
    uniqueGroupSessionHistory(customerId,(response) => { // Chat History storing the messages      
    setCarouselData(response.data);
    dispatch(appLevelFeedback(response.data))
    setIsLoading(false)
    }, (err) => {
      dispatch(apiError(err.message));
      dispatch(loader(false));
    });
  }
 catch(err){
  console.log('Error Message Occured in Group Session history Api call',err.message);
}
  }

  useEffect(() => {
    if (name) {
      storeMessage()
    }
  }, [modalDeleteState])

  const handleFocus = () => {
    setIsPlaceHolderVisible(false);
    setIsFocused(true);
    setIsSendButtonDisabled(false);
  }

  const handleInputBlur = () => {
    setIsFocused(false);
    setIsSendButtonDisabled(true);
  }


  useEffect(() => {
    prevIndexRef.current = currentMsgIndex;
  }, [currentMsgIndex])

  useEffect(() => {
    isMounted.current = true;
    if (isAnimating && !showCarousel) {
      startMsgAnimation();
    }
    return () => {
      clearInterval(intervalRef.current);
      isMounted.current = false;
    };
  }, [isAnimating, showCarousel, inputMsg.length]);

  const startMsgAnimation = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      if (isMounted.current) {
        setCurrentMsgIndex((prevIndex) => {
          const newIndex = (prevIndex + 1) % inputMsg.length;
          localStorage.setItem('questionIndex', newIndex);
          return newIndex;
        });
      }
    }, 6000);
  };


  const onHandleInputChange = (e) => {
    const inputValue = e.target.value
    setInput(inputValue)
    setIsSendButtonDisabled(inputValue.trim() === '');
  }
   
  const onHandAnimation=()=>{
    dispatch(isStream(true))
    dispatch(conversationList([]));
    setAnimateState(true);
    setOnboardLoading(true);
    dispatch(loader(false))
    dispatch(userPrompt(input));
    setTimeout(() => {
      navigate("/chat");
    }, 2000)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsPlaceHolderVisible(true);
        startMsgAnimation();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const handleCaroselAnimate = (ques) => {
    setInput(ques);
    setIsPlaceHolderVisible(false);
    setIsSendButtonDisabled(false);
    dispatch(loader(false))
    const element = document.getElementById("para");
    if (element) {
      element.focus();
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (document.getElementById("next")) {
        document.getElementById("next").click()
      }
    }, 3000);
  }, [showCarousel])

  return (
    <div>
      <div className={`container container-sm container-xs container-md container-xl container-xxl container-fluid useronboard  mx-auto py-5  img-fluid px-0 ${animateState ? 'backgroundOut' : ''} ${blurBackground ? 'blur' : ''}`}>
        <div className={`${isBackFromArchive ? 'animateOnboardCarousel' : ""}`}>
          <div className="row align-items-center justify-content-center">
            <div className={`logo px-0 ${showLogo ? 'fade-in-logo' : 'fade-out-logo'} ${isKeyBoardOpen ? 'focused' : ''}`}>
              <img
                src="./static/assets/AskRalphBetaLogo.svg"
                className="img-fluid logo-img"
                width="400"
                height="100.998px"
                alt="no img"
              />
            </div>
            {isSearchBoxVisible && !isLoading ? (
              <div className={`chatbox box-msg d-flex justify-content-center align-items-center ${!isBackFromArchive && 'animateSearch'}  ${animateState ? 'animateSearchOut' : ''} ${isKeyBoardOpen ? 'focused' : ''}`}>
                <input value={input} ref={inputRef} className="form-control msg" type="text" id="para"
                  // autoFocus
                  inputMode="search"
                  disabled={name === "" || name === null || name === undefined}
                  onFocus={handleFocus}
                  onBlur={handleInputBlur}
                  onChange={(e) => onHandleInputChange(e)}
                  onKeyDown={(e) => {
                    if (e.code === "Enter") {
                      e.preventDefault()
                      onHandAnimation()
                      dispatch(isStream(true))
                    }
                  }}
                />

                {isPlaceHolderVisible && !input &&
                  <div className="d-block align-middle">

                    <span onClick={() => { document.getElementById("para").focus() }} className={`leJeuneDeck col-12 message-fade ${currentMsgIndex ? 'animation-msg' : ''}`}>{inputMsg[currentMsgIndex]}</span>
                  </div>
                }

                {!onboardLoading ? (
                  <button
                    name="submitMessage"
                    onClick={() => { onHandAnimation() }}
                    className={`arrow-dist send-arrow-position ${isSendButtonDisabled ? "disabled" : "enabled"
                      } `}
                    disabled={!input || (isFocused || input.trim() === '') && isSendButtonDisabled}
                  >
                    <img
                      src={
                        isSendButtonDisabled
                          ? "/static/assets/arrowlong.svg"
                          : "/static/assets/bluearrow.svg"
                      }
                      height="36px"
                      width="36px"
                      alt="arrow-img"
                      className="arrow_long"
                    />
                  </button>
                ) : (
                  <div className="onboard-loader" role="status"></div>
                )}

              </div>) : <></>}
          </div>
          <br />

          {name && showLogo &&
            <div className="row d-grid mx-0">

              <div className={`container-fluid userOnboardContainer col-12 col-sm-10 col-xl-9 px-xl-4 px-xxl-5  ${animateState && 'carousel-out'}`}>
                {!isLoading && carouselData && carouselData.length === 0 && (
                  <>
                    {promptsVisible ? (
                      <PromptsCarousel
                        handleCaroselAnimate={(q) => handleCaroselAnimate(q)}
                        isBackFromArchive={isBackFromArchive}
                      />
                    ) : <></>}
                  </>
                )}
                {!isLoading && carouselData && carouselData.length > 0 && (
                  <ArchiveCarousel
                    handleCaroselAnimate={(q) => handleCaroselAnimate(q)}
                    data={carouselData}
                    setModalDeleteState={setModalDeleteState}
                    inputRef={inputRef}
                    isBackFromArchive={isBackFromArchive}
                  />
                )}
              </div>
            </div>}
          {modalFlag && <ApplicationFeedback />}
        </div>
      </div>
      {showMobileCarousel && !isLoading && carouselData && carouselData.length === 0 && !carouselData.isUserExist &&
        <div className={`opacityCarousel  positionCarouselOnboard ${onboardCarouselFadeOut ? 'opacityImagesCarousel' : ''}`}>
          <OnboardCarousel handleGetStartedClick={handleGetStartedClick} handleSkip={handleSkip} />
        </div>
      }
    </div>
  );
}

export default Useronboard;    