import { CONFIG_URL } from "./Settings/envConstants";
const apiUrl = CONFIG_URL.apiUrl;

const ChatAPIService = {

  postRequest: async (userPrompt, sessionId, userId, controllerSignal) => {

    try {
      const requestParams = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: userId,
          userPrompt: userPrompt,
          sessionId: sessionId,
          stream: true
        }),
        signal: controllerSignal,
      }

      let response = null;

      if(process.env.REACT_APP_NODE_ENV && process.env.REACT_APP_NODE_ENV === 'local') {
        response = await fetch(apiUrl + 'chat/chat', requestParams);
      } else {
        response = await fetch('chat/chat', requestParams);
      }

      if (!response.ok) {
        // throw new Error('Request failed');
        throw new Error('An error occurred. Please try again');
      }

      return response;
    } catch (error) {
      console.error('Error:', error);
      if (error.name === 'AbortError') {
        localStorage.setItem('storeChat', false)
        throw "Oops, Request cancelled"
      } else {
        throw error;
      }
    }
  },
};

export default ChatAPIService;
