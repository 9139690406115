function PageNotFound() {
    return (
        <div className=" d-flex justify-content-center align-items-center pagenotfound">
            <h1>
                NO Page Found
            </h1>
        </div>
    );
}

export default PageNotFound;