export const mobileImages = [
    '/static/assets/HeaderBackgroundImages/mobile_bg1.jpg',
    '/static/assets/HeaderBackgroundImages/mobile_bg2.jpg',
    '/static/assets/HeaderBackgroundImages/mobile_bg3.jpg',
    '/static/assets/HeaderBackgroundImages/mobile_bg4.jpg',
    '/static/assets/HeaderBackgroundImages/mobile_bg5.jpg',
  ];

  export const desktopImages = [
    '/static/assets/HeaderBackgroundImages/desktop_bg1.jpg',
    '/static/assets/HeaderBackgroundImages/desktop_bg2.jpg',
    '/static/assets/HeaderBackgroundImages/desktop_bg3.jpg',
    '/static/assets/HeaderBackgroundImages/desktop_bg4.jpg',
    '/static/assets/HeaderBackgroundImages/desktop_bg5.jpg',
  ];
