import { createSlice } from '@reduxjs/toolkit'

export const chatSlice = createSlice({
    name: 'chat',
    initialState: {
      conversationList: [],
      storeChat: false,
      apiError: false,
      sessionHistory: [],
      groupSessionHistory : [],
      deleteChat : false,
      searchBoxVisible : true,
      userCheck : false,
      isStream:true,
      isStopButtonTriggered: false,
      lastConversation: ""
    },
    reducers: {
      conversationList: (state, action) => {
       state.conversationList = [...action.payload]
      },
      storeChat: (state, action) => {
        state.storeChat = action.payload
      },
      sessionHistory: (state, action) => {
        state.sessionHistory = action.payload
      },
      groupSessionHistory: (state, action) => {
        state.groupSessionHistory = action.payload
      },
      deleteChat: (state, action) => {
        state.deleteChat = action.payload
      },
      apiError: (state, action) => {
        state.apiError = action.payload
      },
      searchBoxVisible: (state, action) => {
        state.searchBoxVisible = action.payload
      },
      userCheck: (state, action) => {
        state.userCheck = action.payload
      },
      isStream: (state, action) => {
        state.isStream = action.payload
      },
      isStopButtonTriggered: (state, action) => {
        state.isStopButtonTriggered = action.payload
      },
      lastConversation: (state, action) => {
        state.lastConversation = action.payload
      },
    },
  })
 
  // Action creators are generated for each case reducer function
  export const { conversationList, storeChat, apiError, sessionHistory,groupSessionHistory, deleteChat,  searchBoxVisible,userCheck, isStream, isStopButtonTriggered, lastConversation } = chatSlice.actions
 
  export default chatSlice.reducer
 
