/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import TextOnly from "../Components/StreamingComponents/TextOnly";
import SpecificProductDetails from "../Components/StreamingComponents/SpecificProductDetails";
import BrandingCard from "../Components/StreamingComponents/BrandingCard";
import SingleCategoryList from "../Components/StreamingComponents/SingleCategoryList";
import { useSelector, useDispatch } from "react-redux";
import {
  apiError,
} from "../Services/Redux/Reducers/chatSlice";
import {
  uniqueSessionHistory,
} from "../Services/ApiServices/chatServices";
import { loader } from "../Services/Redux/Reducers/commonSlice";
import MultiCatTemplate from "../Components/MultiCatCardTemplate/MultiCatTemplate";
import { useMsal } from "@azure/msal-react";
import { useLocation } from "react-router-dom";
import ChatOperation from "./ChatOperation";
import { ChatHistoryProps } from "../Services/CommonServices/propTypes";
import ProductListingGrid from "../Components/StreamingComponents/ProductLisitingGrid";

function ChatHistory(props) {
  const { accounts } = useMsal();
  const [ setShortName] = useState("");
  const [ setName] = useState("");
  const [data, setData] = useState([]);
  const [isScreenResponsive, setIsScreenResponsive] = useState(false);
  const loading = useSelector((state) => state.common.isLoader);
  const dispatch = useDispatch();
  const [isMobileHeader, setIsMobileHeader] = useState(true);
  const location = useLocation();
  const { userId, sessionId } = location.state;

  const storeMessage = () => {
    const params = [userId, sessionId];
    // const params = ['bhavana.tomar@ralphlauren.com','b116faa8-1ac2-4bbd-9062-05514e9ca964']
    uniqueSessionHistory(
      params,
      (response) => {
        // Chat History storing the messages
        setData(response.data.data);
      },
      (err) => {
        dispatch(apiError(err.message));
        dispatch(loader(false));
      }
    );
  };

  useEffect(() => {
    storeMessage();
    if (accounts.length !== 0) {
      if (accounts[0].name.includes(",")) {
        let firstname = accounts[0].name.split(",")[1];
        firstname = firstname.split(" ")[1];
        let lastname = accounts[0].name.split(",")[0];
        setName(firstname + " " + lastname);
        setShortName(firstname.charAt(0) + lastname.charAt(0));
      } else {
        let firstname = accounts[0].name;
        setName(firstname);
        setShortName(firstname.charAt(0));
      }
    }
  }, [accounts]);

  const handleValueChange = (childValue) => {
    props.onValueChange(childValue);
  };

  //iphone css fix
  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    let userAgent = window.navigator.userAgent.toLowerCase(),
      macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i,
      windowsPlatforms = /(win32|win64|windows|wince)/i,
      safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (
      macosPlatforms.test(userAgent) ||
      windowsPlatforms.test(userAgent) ||
      (/android/.test(userAgent) && !safari)
    ) {
      setIsScreenResponsive(true);
    }
  }, []);

  useEffect(() => {
    if(window.innerWidth > 425) {
      setIsMobileHeader(false)
    }
    else{
      setIsMobileHeader(true)
    }
    }, [window.innerWidth])

  return (
    <div>
      <div className="d-flex flex-column">
        <div
          className={`homepage ${loading ? "homepageresize" : ""} 
        ${loading && isScreenResponsive
              ? "res_homepageresize"
              : isScreenResponsive
                ? "res_homepage"
                : ""
            }`}
          id="pageContainer"
        >
          <div className="page-container history-container">
            <div className="chatwindow">
              <div className="history-conversationlist chat_options">
                <div
                  className={`container container-fluid min-height px-0 px-md-3 ${isScreenResponsive && "res_min-height"
                    }`}
                >
                  <div style={{ height: "100px" }}></div>
                  <div className="messageHistory">
                    {
                      data && data.length > 0 && data.map((con, index) => (
                        <div key={"history" + index}>
                          <div className="col-11 justify-self-end">
                            {/* <input type="checkbox" id={con.id} key={"history"+index} onClick={(e) => selectMessages(e)}/> */}
                            <div
                              className={`chat_msg ms-auto me-3 me-sm-1 fadeOutPromts`}
                            >
                              <div className="d-flex align-items-start ps-0">
                                <span className="badge-text">
                                  {con.question}
                                </span>
                              </div>
                            </div>
                          </div>
                          {((con &&
                            con.response?.responseType &&
                            con.response?.responseType === "ProductListing") ||
                            (con &&
                              con.response?.responseType === "ProductDetail") ||
                            (con &&
                              con.response?.responseType ===
                                "ProductCollectionListing")) && (con.backgroundImage && con.desktopbackgroundImage) && (
                            <>
                              <div className={`${!isMobileHeader && "d-none"}`}>
                                <img
                                  src={con.backgroundImage}
                                  height="430px"
                                  width="375px"
                                  alt="headerImg"
                                  className="imgHeader"
                                />
                              </div>
                              <div className={`${isMobileHeader && "d-none"}`}>
                                <img
                                  src={con.desktopbackgroundImage}
                                  alt="headerImg"
                                  className="imgHeader"
                                />
                              </div>
                            </>
                          )}
                          {Object.keys(con.response).length > 0 && (
                            <div>
                              {con &&
                                con.response?.responseType === "Error" &&
                                con.response?.error !==
                                "Oops, Request cancelled" && (
                                  <div className="text-danger">
                                    {con.response.error}
                                  </div>
                                )}
                              {con &&
                                con.response?.responseType &&
                                con.response?.responseType === "TextOnly" && (
                                  <TextOnly
                                    index={index}
                                    responseType={con.response.responseType}
                                    text={con.response.responseText}
                                    conclusion={con.response.responseConclusion}
                                    delay={20}
                                    isAnswerComplete={props.isAnswerComplete}
                                    stopClick={true}
                                    effects={false}
                                  />
                                )}
                              {con &&
                                con.response?.responseType &&
                                con.response?.responseType ===
                                "BrandingInformation" && (
                                  <BrandingCard
                                    index={index}
                                    text={con.response.responseText}
                                    branding={con.response.branding}
                                    conclusion={con.response.responseConclusion}
                                    delay={10}
                                    isAnswerComplete={props.isAnswerComplete}
                                    stopClick={true}
                                    effects={false}
                                  />
                                )}
                              {con &&
                                con.response?.responseType &&
                                con.response?.responseType ===
                                  "ProductListing" && (
                                  // <SingleCategoryList
                                  //   index={index}
                                  //   responseTitle={con.response.responseTitle}
                                  //   text={con.response.responseText}
                                  //   branding={con.response.branding}
                                  //   products={con.response.products}
                                  //   conclusion={con.response.responseConclusion}
                                  //   delay={20}
                                  //   isAnswerComplete={props.isAnswerComplete}
                                  //   stopClick = {true}
                                  //   // onValueChange={handleValueChange}
                                  //   effects={false}
                                  // />
                                  <ProductListingGrid
                                    index={index}
                                    responseTitle={con.response.responseTitle}
                                    text={con.response.responseText}
                                    branding={con.response.branding}
                                    products={con.response.products}
                                    conclusion={con.response.responseConclusion}
                                    delay={20}
                                    isAnswerComplete={props.isAnswerComplete}
                                    stopClick={true}
                                    effects={false}
                                  />
                                )}
                              {con &&
                                con.response?.responseType ===
                                "ProductDetail" && (
                                  <SpecificProductDetails
                                    index={index}
                                    responseTitle={con.response.responseTitle}
                                    text={con.response.responseText}
                                    branding={con.response.branding}
                                    product={con.response.products}
                                    conclusion={con.response.responseConclusion}
                                    delay={20}
                                    isAnswerComplete={props.isAnswerComplete}
                                    stopClick={true}
                                    effects={false}
                                  />
                                )}
                              {/* Multi Outfit Template */}
                              {con &&
                                con.response?.responseType ===
                                "ProductCollectionListing" && (
                                  <div>
                                    <MultiCatTemplate
                                      index={index}
                                      responseType={con.response.responseType}
                                      responseTitle={con.response.responseTitle}
                                      text={con.response.responseText}
                                      branding={con.response.branding}
                                      products={con.response.productCollections}
                                      conclusion={
                                        con.response.responseConclusion
                                      }
                                      delay={20}
                                      isAnswerComplete={props.isAnswerComplete}
                                      stopClick={true}
                                      effects={false}

                                    />
                                  </div>
                                )}
                            </div>
                          )}
                        </div>
                      ))}
                    {data && data.length > 0 &&
                      <ChatOperation data={location.state} />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ChatHistory.propTypes = ChatHistoryProps;

export default ChatHistory;
