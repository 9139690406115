import { configureStore } from '@reduxjs/toolkit'
import commonSlice from './Reducers/commonSlice'
import chatSlice from './Reducers/chatSlice'
import feedBackSlice from './Reducers/feedBackSlice'
import userSlice from './Reducers/userSlice'

export default configureStore({
  reducer: {
    common: commonSlice,
    chat: chatSlice,
    feedBack:feedBackSlice,
    user:userSlice
  },
})