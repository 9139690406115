import React from 'react';
import PropTypes from 'prop-types';

function DeleteModal(props) {

  if (!props.visibleModal) return null;



  return (

    <div> {props.visibleModal &&
      <div className="modal d-flex fade show mainContainer" id="myModal" role="dialog" style={{ display: "block" }} tabIndex="-1" aria-hidden="true" >
        <div className="modal-dialog" style={{ borderRadius: "10px" }}>
          <div className="modal-content">
            <div className='modal-content_inner'>
              <div className="modal-header cross">
                <button name="closeButton" type="button" className="closebtn" data-bs-dismiss="modal" aria-label="Close" onClick={props.handleClose}><img src="./static/assets/Close_icon.svg" className="close_icon" /></button>
              </div>

              <div className="modal-body">
                <div className='modal-body_title'>
                  <span className='modal-body_headingText'>Are you sure you want to delete the chat?</span>
                </div>

                <div className='modal-body_suggestedFeedback modal_body_title2'>
                  <p>By deleting the chat this message will no longer be available for access.</p>
                </div>


                <div className="d-flex justify-content-center" style={{ paddingTop: "30px" }}>
                  <button name="deleteChatButton" className='btnChat delete-btn' onClick={props.handleDelete}>delete chat</button>
                  <button name="cancelButton" className='btnChat cancel-btn' style={{ marginLeft: "10px" }} onClick={props.handleClose}>Cancel</button>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    }</div>

  )
}

DeleteModal.propTypes = {
  visibleModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired
};

export default DeleteModal;
